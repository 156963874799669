import React from 'react';
import { FunctionComponent, ReactNode, useCallback } from 'react';

import { Button } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';

import './controlled-accordion.component.scss';

interface Props {
  labelKey?: ResourceKey;
  isExpanded: boolean;
  withModifyButton?: boolean;
  onToggleAccordion: (isExpanded: boolean) => any;
  renderHeader?: ((isVisible?: boolean) => JSX.Element) | JSX.Element;
  children?: ReactNode;
}

export const ControlledAccordion: FunctionComponent<Props> = ({renderHeader, labelKey, isExpanded, withModifyButton, children, ...props}) => {
  const toggleAccordion = useCallback(() => props.onToggleAccordion(!isExpanded), [isExpanded]);

  return (
    <ErrorBoundary>
      <div className={'controlled-accordion accordion-wrapper' + (isExpanded ? ' is-expanded' : '')}>
        <div className="accordion-header" onClick={toggleAccordion}>
          <div className="row">
            <div className="col-sm-9">
              <span className="ns-icon ns-expand"/>
              <span className="ns-icon ns-next"/>
              {(
                renderHeader
                  ? (
                    typeof renderHeader === 'function'
                      ? renderHeader(isExpanded)
                      : renderHeader
                  )
                  : <h2><Translation resource={labelKey}/></h2>
              )}
            </div>
            <div className="col-sm-3 accordion-header-button-bay">
              {withModifyButton &&
              <span>
                <Button id="btn-modify-search" btnLink track className="hidden-xs js-modify-search-button space-outer-top-xs" resource="MODIFY_SEARCH"/>
                <Button
                  id="btn-modify-search-mobile"
                  btnDefault
                  track
                  mobileResponsive
                  className="hidden-lg hidden-md hidden-sm js-modify-search-button  mobile-modify-search-button"
                  resource="MODIFY_SEARCH"
                />
              </span>
              }
            </div>
          </div>
        </div>
        <div className="accordion-content">
          {children}
        </div>
      </div>
    </ErrorBoundary>
  );
};
